import React from 'react'

const ContactUs = () => {
    return (
        <>
            <h2 className="uk-text-center uk-text-bolder uk-text-white">Contact us</h2>

            <div className="uk-grid uk-child-width-1-2@s uk-text-white">


                <div>
                    <hr className="uk-divider-icon"></hr>
                    <h4 className="uk-text-bold uk-text-white">SYDNEY</h4>
                    <div className="uk-align-center">
                        <span uk-icon="user" /> Ambupad Thakur
                        <br></br>
                        <span uk-icon="mail" /> ambupadthakur@hotmail.com
                        <br></br>
                        <span uk-icon="phone" /> +61 423 661 835
                    </div>

                    <div className="uk-margin-top">
                        <span uk-icon="user" />  Subhas Nandy
                        <br></br>
                        <span uk-icon="mail" />  subhasnandy@gmail.com
                        <br></br>
                        <span uk-icon="phone" />  +61 427 429 187
                    </div>
                </div>

                <div>
                    <hr className="uk-divider-icon"></hr>
                    <h4 className="uk-text-bold uk-text-white">ADELAIDE</h4>
                    <div>
                        <span uk-icon="user" /> Biplob Bal
                        <br></br>
                        <span uk-icon="mail" /> biplobkr_bal@yahoo.com
                        <br></br>
                        <span uk-icon="phone" /> +61 401 875 648
                    </div>

                    <div className="uk-margin-top">
                        <span uk-icon="user" />  Pranit Saha
                        <br></br>
                        <span uk-icon="mail" />  pranit44@gmail.com
                        <br></br>
                        <span uk-icon="phone" />  +61 426 254 834
                    </div>
                </div>

                <div>
                    <hr className="uk-divider-icon"></hr>
                    <h4 className="uk-text-bold uk-text-white">BRISBANE</h4>
                    <div>
                        <span uk-icon="user" /> Sudipto Das Bhowmik
                        <br></br>
                        <span uk-icon="mail" /> sudiptadasbhowmik@gmail.com
                        <br></br>
                        <span uk-icon="phone" /> +61 450 230 337
                    </div>

                </div>

                <div >
                    <hr className="uk-divider-icon"></hr>
                    <h4 className="uk-text-bold uk-text-white">Perth</h4>
                    <div>
                        <span uk-icon="user" /> Gurucharan Pradhan
                        <br></br>
                        <span uk-icon="mail" /> gurucharanpradhan05@gmail.com
                        <br></br>
                        <span uk-icon="phone" /> +61 420 805 897
                    </div>

                    <div className="uk-margin-top">
                        <span uk-icon="user" />  Dipak Sarkar
                        <br></br>
                        <span uk-icon="mail" />  rsdipaksarkar@gmail.com
                        <br></br>
                        <span uk-icon="phone" />  +61 430 029 071
                    </div>
                </div>

                <div >
                    <hr className="uk-divider-icon"></hr>
                    <h4 className="uk-text-bold uk-text-white">DARWIN</h4>
                    <div>
                        <span uk-icon="user" /> Suman Das
                        <br></br>
                        <span uk-icon="mail" /> dassumonkumar1@gmail.com
                        <br></br>
                        <span uk-icon="phone" /> +61 406 314 587
                    </div>


                </div>
                <div>

                </div>
                <div >
                    <hr className="uk-divider-icon"></hr>
                    <h4 className="uk-text-bold uk-text-white">Melbourne</h4>
                    <div>
                        <span uk-icon="user" /> Souvik Guha
                        <br></br>
                        <span uk-icon="mail" /> rimo.guha@gmail.com
                        <br></br>
                        <span uk-icon="phone" /> +61 469 054 905
                    </div>
                </div>
            </div>
            <hr className="uk-divider-icon"></hr>
            <span className="uk-text uk-text-secondary">For any queries related to the website, please reach us at <b>satsangoceania@gmail.com</b></span>
        </>
    )
}

export default ContactUs;
