import React ,{useState} from 'react'
import SpinnerContext from './spinnerContext';


const SpinnerState = (props) => {
    const initialState={
        spinner:false
    }

    
        const [state, setState]= useState(initialState);

    const startSpinner = ()=>{
        console.log("Start Spinner");
        setState({...state,spinner:true});
    }
    const stopSpinner = ()=>{
        console.log("Stop Spinner");
        setState({...state,spinner:false});
    }


    return (
        <SpinnerContext.Provider value={{startSpinner,stopSpinner,spinner:state.spinner}}>
             {props.children}
        </SpinnerContext.Provider>
    )
}

export default SpinnerState
