import React, { useState } from 'react'
import jsonQuery from 'json-query'
import { globalprayertime } from '../prayertime/globalprayertime';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';

const PrayerTime = () => {

  const initialState = {
    country: "",
    region: ""

  }



  const [filter, setFilter] = useState(initialState);

  const { country, region } = filter;

  const getTime = () => {
    if (country == "" | region == "") {
      return ""
    }
    const time = jsonQuery(`${country}.${region}`, { data: globalprayertime });
    console.log("Value is:");
    console.log(time.value);
    return time.value;
  }
  const selectCountry = (val) => {
    setFilter({ ...filter, "country": val });
  }
  const selectRegion = (val) => {
    setFilter({ ...filter, "region": val });
  }

  return (
    <div>
      <div className="uk-child-width-1-2@s uk-padding-small">


        <div className="uk-padding-small">

          <CountryDropdown name="country" whitelist={"AU"} className="uk-select" value={country} onChange={(val) => selectCountry(val)} />
        </div>

        {(country != "") &&
          <div className="uk-padding-small">
           
            <RegionDropdown
              showDefaultOption="true"
              defaultOptionLabel="Select State *"
              className="uk-select"
              country={country}
              value={region}

              onChange={(val) => selectRegion(val)} />
          </div>
        }
      </div>
      <h3 className="uk-text-center uk-text-white"><u>PRAYER TIME</u></h3>
      <table id="prayertable" className="uk-table uk-table-striped">

        <thead>
          <tr>
            <th className="uk-text-center">Month</th>
            <th className="uk-text-center">Morning Prayer Time</th>
            <th className="uk-text-center">Evening Prayer Time</th>
          </tr>
        </thead>
        <tbody>
          {getTime().length > 0 && getTime().map(item =>
            <>
              <tr>
                <td className="uk-text-center">{item.month}</td>
                <td className="uk-text-center" >{item.morning}</td>
                <td className="uk-text-center">{item.evening}</td>
              </tr>
            </>
          )}
        </tbody>
      </table>


    </div>
  )
}

export default PrayerTime
