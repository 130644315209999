import React from 'react'
import moment from 'moment'

const eventtable = ({ events, history, url, currview }) => {

  const OnSelectRow = (event) => {
    console.log("Inside row")
    console.log(event._id);
    history.push({
      pathname: "/" + url,
      state: { ...event }

    })
  }
  const currentlyRunning = (event) => {
    // let currtime = moment(Date.now()).format('YYYY-MM-DD hh:mm A');
    let currtime = Date.now();
    //console.log(currtime);
    console.log("Evnt time:" + moment(event.starttime))
    console.log("Evnt end time:" + moment(event.endtime))
    // console.log(moment(event.starttime) < currtime && moment(event.endtime) > currtime)
    var value = "";
    (event.meetingmode == "ONLINE") ? value = "JOIN" : value = "OFFLINE"
    if (moment(event.starttime) < currtime && moment(event.endtime) > currtime) {

      if (event.meetingmode == "ONLINE") {
        return <a href={event.meetingurl}  className="uk-text uk-text-success uk-text-bold" target="_blank" >{value}
          <span uk-spinner="ratio: 1.2"></span>
        </a>
      } else {
        return <span uk-spinner="ratio: 1.2">OFFLINE</span>
      }

    } else if (moment(event.starttime) > currtime) {
      return <span className="uk-text uk-text-primary" >NOT STARTED</span>
    }
    else if (moment(event.endtime) < currtime) {
      return <span className="uk-text uk-text-danger" >EXPIRED</span>
    }
  }

  //onClick={() => OnSelectRow(event)}
  return (
    <div className="uk-width-1-1">

      <table className="uk-table uk-table-striped uk-table-hover">
        <h6 className="uk-text-meta uk-text-small ">Click record to view more details</h6>

        <thead >
          <th className="uk-background-cb-2 uk-text-white uk-text uk-text-break">Event Name</th>
          <th className="uk-background-cb-2 uk-text-white">Country</th>
          <th className="uk-background-cb-2 uk-text-white">Start Time</th>
          <th className="uk-background-cb-2 uk-text-white">
            {(currview == "today") && "End Time"}
            {(currview != "today") && "Date"}
          </th>

          <th className="uk-background-cb-2 uk-text-white">Link</th>

        </thead>
        <tbody>

          {events.map(event =>
            <tr >
              <td className=" uk-text " onClick={() => OnSelectRow(event)}>{event.eventname}
              </td>
              <td onClick={() => OnSelectRow(event)}>{event.country}</td>
              <td onClick={() => OnSelectRow(event)}>{event.starttime.substring(11)}</td>
              <td onClick={() => OnSelectRow(event)}>
                {(currview == "today") && event.endtime.substring(11)}
                {(currview != "today") && event.endtime.substring(0, 10)}

              </td>
              <td>
                {currentlyRunning(event)}
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {events.length == 0 &&
        <span className="uk-text-center uk-background-muted">No Records Found</span>}
    </div>
  )
}

export default eventtable
