import React, { Fragment } from 'react';
import './uikit.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Navbar from './components/layout/Navbar';
import Home from './components/pages/Home';
import About from './components/pages/About';
import PrayerTime from './components/pages/PrayerTime';
import Program from './components/pages/Program';
import PublicEvents from './components/events/publicevent';
import AlertState from './context/alert/AlertState';
import SpinnerState from './context/spinner/SpinnerState';
import Alert from './components/layout/AlertContainer'
import Spinner from './components/layout/Spinner'
import Event from './components/events/addevent'
import ContactUs from './components/pages/ContactUs';

function App() {

  return (
    <AlertState>
          <SpinnerState>
            <Router>
              <Fragment>
                <Navbar />
                <div className="uk-inline uk-flex uk-background-secondary ">
                  <div className="uk-container uk-width-1-1 uk-margin-small-top uk-width-3-4@s">
                  <Alert />
                    <Switch>
                      <Route exact path="/" component={Home} />
                      <Route exact path="/prayertime" component={PrayerTime} />
                      <Route exact path="/Program" component={Program} />
                      <Route exact path="/About" component={About} />                  
                      <Route exact path="/publicevents" component={PublicEvents} /> 
                      <Route exact path="/addnewevent" component={Event} />      
                      <Route exact path="/contactus" component={ContactUs} />            
                    </Switch>
                  </div>
                  <Spinner />
                </div>
             
              </Fragment>
            </Router>
            </SpinnerState></AlertState>
      
  );
}

export default App;
