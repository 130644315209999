import React from 'react'

import EventItem from './eventitem';

const event = (props) => {
   
       
    return (
       <>
      <EventItem event={props.history.location.state} />
    
      </>
    )
}

export default event
