import React from 'react'

const About = () => {
  return (
    <div className="uk-text-white">

      
      <img className="uk-img uk-padding-small uk-width-1-1" src="https://soimages.s3.ap-southeast-2.amazonaws.com/Thakur.png"></img>

      <p className="uk-text-italic uk-padding uk-text-xlarge  ">

        Satsang Oceania is spiritually affiliated under the charitable and philanthropic
  organisation, “SATSANG” at Deoghar, Jharkhand, India, founded by Anukulchandra
  Chakravarty, reverentially known as Sree Sree Thakur Anukulchandra (herein after referred
  to as Sree Sree Thakur), and with Acharyadev (Spiritual Head) of Satsang Deoghar,
  presently Sree Sree Arkadyuti Chakraborty, as its Spiritual Head and Supreme Guide.
      </p>
      <p className="uk-text-italic uk-padding uk-text-xlarge ">

        Satsang Oceania is a non-profit (not-for-profit) institution and shall operate exclusively for
  spiritual, religious, social and charitable purposes. Satsang Oceania’s purpose is based on and
  draws inspiration from the ideals, teachings and philosophy of the modern day spiritual
  master, philosopher and guide, Sree Sree Thakur. Satsang Oceania will strive to promote
  Dharma, which upholds the existence and growth of every individual irrespective of age,
  gender, race, religion, national origin or any other factors that may discriminate in any way.
  </p>

      <div className="uk-card uk-card-body  uk-text-large uk-text-small@s uk-background-cb-2 uk-text-center uk-width-1-1 uk-margin-bottom ">
        <div className="uk-text-center uk-text-bolder"><u>REFERENCES</u></div>
        <div className="">
          <span>Satsang Deoghar: </span>
          <span>
            <a className="uk-text-secondary" href="https://www.satsang.org.in/"><b>https://www.satsang.org.in</b></a>
            </span>
        </div>
        <div>
          <span>Satsang America: </span>
          <span>
            <a className="uk-text-secondary"href="https://www.satsangamerica.org/">
              <b>https://www.satsangamerica.org</b></a>
            </span>

        </div>
        <div>
          <span>Satsang UK:   </span>
          <span>
            <a className="uk-text-secondary" href="http://www.satsang.org.uk/">
              <b>http://www.satsang.org.uk</b></a>
            </span>
        </div>
      </div>
      </div>
      )
    }
    
    export default About
