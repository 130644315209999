import React, { useState, useContext } from 'react'
import TimezoneSelect from 'react-timezone-select'
import { Link } from 'react-router-dom';
import moment from 'moment';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import axios from 'axios';
import AlertContext from '../../context/alert/alertContext';
import SpinnerContext from '../../context/spinner/spinnerContext'
const Addevent = (props) => {
    let event = props.history.location.state
    console.log(JSON.stringify(event));
    const alertContext = useContext(AlertContext);
    const spinnerContext = useContext(SpinnerContext);

    const { startSpinner, stopSpinner } = spinnerContext;
    const { setAlert } = alertContext;

    const [selectedTimezone, setSelectedTimezone] = useState({})

    let intitialState = {
           
        email: "",
        eventname: "",
        meetingmode: "ONLINE",
        meetingurl: "",
        starttime: "",
        endtime: "",
        hostname: "",
        hostaddress: "",
        phone: "",
        country: "",
        region: ""
    };

    let componentState={};
    if (event) {
        componentState = event
    } else {
        componentState = {
           
            email: "",
            eventname: "",
            meetingmode: "ONLINE",
            meetingurl: "",
            starttime: "",
            endtime: "",
            hostname: "",
            hostaddress: "",
            phone: "",
            country: "",
            region: ""
        }
    }



    const [state, setState] = useState(componentState);

    const { _id,memberref, starttime, endtime, hostname, hostaddress, meetingurl, phone, email, meetingmode, eventname, country, region } = state;


    const onChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        console.log("------------------------");
         console.log("Region is:"+region);
         console.log("------------------------");
        if(country=="" || country== null || region==""||region==null||region==undefined){
            setAlert({ type: "danger", msg: "Either Country or State not selected " });
            return;
        }
        startSpinner();
        let payload = {
            starttime, endtime, hostname, hostaddress, meetingurl, phone, email, meetingmode, eventname,
            timezone: selectedTimezone.value,
            country,
            state: region
        }
         
        if(_id== undefined){
            var api_url=process.env.REACT_APP_API_BASE_URL + "/events/addevent";
        }else{
            payload= Object.assign(payload,{memberref,_id});
            var api_url=process.env.REACT_APP_API_BASE_URL + "/events/updateevent";
        }
       

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        }

        try {
            let response = await axios.post(api_url, payload, config);
            setAlert({ type: "success", msg: response.data.msg })
            setState(intitialState);
        } catch (error) {
            setAlert({ type: "danger", msg: error.response.data.msg })

        }



        stopSpinner();
    }

    const selectCountry = (val) => {
        setState({ ...state, "country": val });
    }
    const selectRegion = (val) => {
        setState({ ...state, "region": val });
    }


    return (
        <div className="uk-container uk-width-1-2@s">
            <Link to="/program" className="uk-margin-top uk-text-bolder uk-button uk-button-link uk-align-right">BACK</Link>

            <form onSubmit={onSubmit}>

                <div>

                   

                    <h6 className="uk-text-lead uk-text-secondary uk-text-meta uk-text-bolder uk-text-large uk-text-center">EVENT DETAILS</h6>
                    <div>
                        <span className="uk-text-meta uk-text-secondary">Event Name:</span>
                        <input className="uk-input" type="text" name="eventname" value={eventname} onChange={onChange} maxLength="150" minLength="5" required />
                    </div>

                    <div className="uk-margin-top">
                        <span className=" uk-text-meta  uk-text-secondary">Meeting Mode:</span>
                        <select className="uk-select" name="meetingmode" onChange={onChange}>
                            <option value="ONLINE" selected={meetingmode=="ONLINE"}>ONLINE</option>
                            <option value="OFFLINE" selected={meetingmode=="OFFLINE"}>OFFLINE</option>
                        </select>
                    </div>

                    <div className="uk-margin-top">
                        <span className="uk-text-meta uk-text-secondary">Set Start time:</span>
                        <input className="uk-input" type="datetime-local" placeholder="Set Start Time" name="starttime" value={starttime} onChange={onChange} required />
                    </div>

                    <div className="uk-margin-top">
                        <span className="uk-text-meta uk-text-secondary">Set end time:</span>
                        <input className="uk-input" type="datetime-local" placeholder="Set End Time" name="endtime" value={endtime} onChange={onChange} required />
                    </div>

                    <div className='select-wrapper uk-margin-top'>
                        <span className="uk-text-meta uk-text-secondary">Set Timezone:</span>
                        <TimezoneSelect className="uk-select" default="Australia/Sydney" value={selectedTimezone} onChange={tz => setSelectedTimezone(tz)} required />
                    </div>

                </div>

                <div className="uk-margin-top">
                    <hr class="uk-divider-icon" />
                    <h6 className="uk-text-lead uk-text-meta uk-text-bolder uk-text-large uk-text-secondary uk-text-center">HOST DETAILS</h6>
                    {(_id != undefined) &&
                        <div className="uk-margin-top-xlarge">
                            <span className="uk-text-meta">Enter Member Ref Key:</span>
                            <input className="uk-input" type="text" name="memberref" value={memberref} onChange={onChange} maxLength="150" minLength="5" required />
                        </div>
                    }
                    
                    <div>
                        <span className="uk-text-meta uk-text-secondary">Host Name:</span>
                        <input className="uk-input" type="text" name="hostname" value={hostname} onChange={onChange} maxLength="30" minLength="5" required />
                    </div>


                    {(meetingmode == "ONLINE") ?



                        <div>
                            <span className="uk-text-meta uk-text-secondary">Meeting Url:</span>
                            <input className="uk-input" type="url" name="meetingurl" value={meetingurl} onChange={onChange} required />
                        </div>
                        :
                        <div>
                            <span className="uk-text-meta uk-text-secondary">Host Address:</span>
                            <textarea className="uk-textarea" rows="5" type="text" name="hostaddress" value={hostaddress} onChange={onChange} required />
                        </div>
                    }
                    <div>
                        <span className="uk-text-meta uk-text-secondary">Phone Number:</span>
                        <input className="uk-input" type="phonenumber" name="phone" value={phone} onChange={onChange} maxLength="30" minLength="5" />
                    </div>

                    <div>
                        <span className="uk-text-meta uk-text-secondary">Email:</span>
                        <input className="uk-input" type="email" name="email" value={email} onChange={onChange} maxLength="30" minLength="5" />
                    </div>
                </div>


                <div >
                    <span className="uk-text-meta uk-text-secondary">Select Country:</span>
                    <CountryDropdown name="country" priorityOptions={["AU", "US", "MY", "IND", "NZ", "NL", "UK", "FR", "IT"]} className="uk-select" value={country} onChange={(val) => selectCountry(val)} />
                </div>
                <div >
                    <span className="uk-text-meta uk-text-secondary">Select State:</span>
                    <RegionDropdown
                        className="uk-select"
                        country={country}
                        value={region}
                        onChange={(val) => selectRegion(val)} />
                </div>







                <button className="uk-button uk-align-center uk-button-primary" type="Submit">SUBMIT</button>
            </form>
            <Link to="/program" className="uk-margin-top uk-text-bolder uk-align-right">BACK</Link>
        </div>
    )
}

export default Addevent
