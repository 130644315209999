import React from 'react'

const Home = () => {
  return (
<>
    
      <div className="uk-flex-center uk-grid uk-child-width-1-1 uk-width-1-1 uk-margin-remove-left">
        <div className="uk-card-body">
        
          <p className="uk-text-xxlarge uk-text-italic uk-text-primary2">
                Sweet tongue, exalting habits and behaviour
                  with services and unexpecting devoted adherence
                to the Ideal is the royal road to win.
                
               </p>
             <h4 className="uk-text-right uk-text-white"><b> -Sri Sri Thakur</b></h4>
        
              
       </div>
     
      <div className="uk-flex-last@s uk-card uk-card-body uk-card-default uk-box-shadow-large uk-flex-first uk-padding-top-small">
        
        <img id="coverpic" src="https://satsanngoceaniaimages.s3-ap-southeast-2.amazonaws.com/thakurcoverpic.jpg" className="uk-height-large uk-width-1-1" alt="" />
      </div>
      </div>

    </>
  )
}

export default Home
