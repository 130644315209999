import React, { useReducer } from 'react'
import AlertContext from './alertContext';
import alertReducer from './alertReducer';
import { SET_ALERT, REMOVE_ALERT } from '../types';

const AlertState = (props) => {

    const initialState = {
        type: "",
        msg: ""
    }


    const [state, dispatch] = useReducer(alertReducer, initialState);

    const setAlert = payload => {
        dispatch({ type: SET_ALERT, payload });

      //  setTimeout(()=>dispatch({ type: REMOVE_ALERT, payload: {} }),5000);
    }

   

    return (
        <AlertContext.Provider value={{ type: state.type, msg: state.msg, setAlert }}>
            {props.children}
        </AlertContext.Provider>
    )
}

export default AlertState
