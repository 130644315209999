import React from 'react'
import PropTypes from 'prop-types';
import NavbarList from './NavbarList';


const Navbar = ({ title }) => {

  return (
    <>
       <nav className="uk-navbar  uk-background-secondary">
        <div className="uk-navbar-left">
          
           <img src="logo111.png"
           className="uk-navbar-item uk-logo uk-margin-top uk-visible@l" width="20%" height="15%" alt="Logo" ></img> 
         
          
       
        <span style={{fontFamily: "serif", color:'#000080', textShadow: '1px 1px 2px', fontSize: '1.8rem'}} className="uk-text-bold  uk-text-uppercase uk-text-xlarge@s uk-text-white">{ title}</span>
        </div>
        <div className="uk-navbar-center uk-visible@l">
          
       
        </div>
        <div className="uk-navbar-right uk-visible@s">
          <ul className="uk-navbar-nav ">
            <NavbarList />
          </ul>
        </div>

        <div className="uk-navbar-right uk-hidden@s" >
          <button className="uk-button uk-button-default" type="button" uk-toggle="target: #toggle-usage"> 
          <span uk-icon="menu" className='uk-text-white'></span>
          </button>
        </div>
      </nav>
      <ul id="toggle-usage" uk-toggle="target: #toggle-usage" className="uk-nav uk-text-center uk-hidden@m" hidden>
        <NavbarList />
        <br></br>
      </ul>
    </>
  )
}

Navbar.propTypes = {
  title: PropTypes.string.isRequired
}
Navbar.defaultProps = {
  title: "Satsang Oceania"
}

export default Navbar
