import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom';






const NavbarList = () => {
  
   return (

    <>
      
          <li>
            <NavLink exact="true" to="/"  activeClassName="uk-active" className="uk-text-bold">Home</NavLink>
          </li>
          <li>
            <NavLink to="/about"  activeClassName="uk-active" className="uk-text-bold ">About</NavLink>
          </li>
          <li>
            <NavLink to="/prayertime"  activeClassName="uk-active" className="uk-text-bold ">Prayer Time</NavLink>
          </li>

          {/* <li>
            <NavLink to="/program" activeClassName="uk-active"  className="uk-text-bold ">Program</NavLink>
          </li> */}
        
          <li>
            <NavLink to="/contactus" activeClassName="uk-active" className="uk-text-bold ">Contact us</NavLink>
          </li>
          <li>
          <a href="https://istavrityportal.satsangoceania.org" className="uk-text-bold ">Istavrity</a>
          </li>
         
     
      
    </>
  )
}



export default NavbarList
