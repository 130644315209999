export const ADD_USER = 'ADD_USER'
export const ADD_MEMBER = 'ADD_MEMBER'
export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'

export const REGISTER_FAIL="REGISTER_FAIL"
export const GET_MEMBERS="GET_MEMBERS"
export const SET_DEPOSIT_ITEM="SET_DEPOSIT_ITEM"
export const CLEAR_DEPOSITS= "CLEAR_DEPOSITS"
export const SET_CURRENT_SUBMISSION="SET_CURRENT_SUBMISSION"
export const SET_ERROR = "SET_ERROR"
export const GET_ALL_SUBMISISSIONS="GET_ALL_SUBMISISSIONS"
export const SET_CURRENT_STATUS="SET_CURRENT_STATUS"
export const SET_ALERT = "SET_ALERT"
export const REMOVE_ALERT="REMOVE_ALERT"
export const GET_ACCOUNTS="GET_ACCOUNTS"