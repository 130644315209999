export const globalprayertime = {
    "Australia": {
        "New South Wales": [
            {
                month: "January",
                morning: "6:00 AM",
                evening: "8:07 PM"
            },
            {
                month: "February",
                morning: "6:29 AM",
                evening: "7:47 PM"
            },
            {
                month: "March",
                morning: "6:54 AM",
                evening: "7:11 PM"
            },
            {
                month: "April",
                morning: "6:17 AM",
                evening: "5:31 PM"
            },
            {
                month: "May",
                morning: "6:40 AM",
                evening: "5:02 PM"
            },
            {
                month: "June",
                morning: "6:57 AM",
                evening: "4:53 PM"
            },
            {
                month: "July",
                morning: "6:55 AM",
                evening: "5:04 PM"
            },
            {
                month: "August",
                morning: "6:31 AM",
                evening: "5:25 PM"
            },
            {
                month: "September",
                morning: "5:52 AM",
                evening: "5:46 PM"
            },
            {
                month: "October",
                morning: "6:00 AM",
                evening: "6:57 PM"
            },
            {
                month: "November",
                morning: "5:43 AM",
                evening: "7:36 PM"
            },
            {
                month: "December",
                morning: "5:39 AM",
                evening: "8:01 PM"
            }
        ],
        "Western Australia": [
            {
                month: "January",
                morning: "5:26 AM",
                evening: "7:24 PM"
            },
            {
                month: "February",
                morning: "5:53 AM",
                evening: "7:06 PM"
            },
            {
                month: "March",
                morning: "6:16 AM",
                evening: "6:32 PM"
            },
            {
                month: "April",
                morning: "6:37 AM",
                evening: "5:54 PM"
            },
            {
                month: "May",
                morning: "6:58 AM",
                evening: "5:27 PM"
            },
            {
                month: "June",
                morning: "7:13 AM",
                evening: "5:19 PM"
            },
            {
                month: "July",
                morning: "7:13 AM",
                evening: "5:30 PM"
            },
            {
                month: "August",
                morning: "6:50 AM",
                evening: "5:49 PM"
            },
            {
                month: "September",
                morning: "6:13 AM",
                evening: "6:08 PM"
            },
            {
                month: "October",
                morning: "5:35 AM",
                evening: "6:28 PM"
            },
            {
                month: "November",
                morning: "5:08 AM",
                evening: "6:53 PM"
            },
            {
                month: "December",
                morning: "5:05 AM",
                evening: "7:17 PM"
            }
        ],
        "Victoria": [
            {
                month: "January",
                morning: "6:16 AM",
                evening: "8:41 PM"
            },
            {
                month: "February",
                morning: "6:48 AM",
                evening: "8:18 PM"
            },
            {
                month: "March",
                morning: "7:18 AM",
                evening: "7:37 PM"
            },
            {
                month: "April",
                morning: "6:46 AM",
                evening: "5:52 PM"
            },
            {
                month: "May",
                morning: "7:13 AM",
                evening: "5:19 PM"
            },
            {
                month: "June",
                morning: "7:32 AM",
                evening: "5:08 PM"
            },
            {
                month: "July",
                morning: "7:30 AM",
                evening: "5:20 PM"
            },
            {
                month: "August",
                morning: "7:02 AM",
                evening: "5:45 PM"
            },
            {
                month: "September",
                morning: "6:18 AM",
                evening: "6:10 PM"
            },
            {
                month: "October",
                morning: "6:21 AM",
                evening: "7:26 PM"
            },
            {
                month: "November",
                morning: "5:59 AM",
                evening: "8.09 PM"
            },
            {
                month: "December",
                morning: "5:53 AM",
                evening: "8:37 PM"
            }
        ],
            "Northern Territory": [
                {
                    month: "January",
                    morning: "6:32 AM",
                    evening: "7:18 PM"
                },
                {
                    month: "February",
                    morning: "6:44 AM",
                    evening: "7:14 PM"
                },
                {
                    month: "March",
                    morning: "6:49 AM",
                    evening: "6:59 PM"
                },
                {
                    month: "April",
                    morning: "6:51 AM",
                    evening: "6:40 PM"
                },
                {
                    month: "May",
                    morning: "6:56 AM",
                    evening: "6:05 PM"
                },
                {
                    month: "June",
                    morning: "7:04 AM",
                    evening: "6:28 PM"
                },
                {
                    month: "July",
                    morning: "7:07 AM",
                    evening: "6:36 PM"
                },
                {
                    month: "August",
                    morning: "6:58 AM",
                    evening: "6:41 PM"
                },
                {
                    month: "September",
                    morning: "8:16 AM",
                    evening: "6:42 PM"
                },
                {
                    month: "October",
                    morning: "6:20 AM",
                    evening: "6:20 PM"
                },
                {
                    month: "November",
                    morning: "6:10 AM",
                    evening: "6:51 PM"
                },
                {
                    month: "December",
                    morning: "6:16 AM",
                    evening: "7:07 PM"
                }
        ],
        "South Australia": [
            {
                month: "January",
                morning: "6:19 AM",
                evening: "8:30 PM"
            },
            {
                month: "February",
                morning: "6:48 AM",
                evening: "8:09 PM"
            },
            {
                month: "March",
                morning: "7:14 AM",
                evening: "7:32 PM"
            },
            {
                month: "April",
                morning: "6:39 AM",
                evening: "5:51 PM"
            },
            {
                month: "May",
                morning: "7:02 AM",
                evening: "5:20 PM"
            },
            {
                month: "June",
                morning: "7:20 AM",
                evening: "5:11 PM"
            },
            {
                month: "July",
                morning: "7:18 AM",
                evening: "5:23 PM"
            },
            {
                month: "August",
                morning: "6:53 AM",
                evening: "5:44 PM"
            },
            {
                month: "September",
                morning: "6:13 AM",
                evening: "6:06 PM"
            },
            {
                month: "October",
                morning: "6:19 AM",
                evening: "7:18 PM"
            },
            {
                month: "November",
                morning: "6:01 AM",
                evening: "7:58 PM"
            },
            {
                month: "December",
                morning: "5:57 AM",
                evening: "8:24 PM"
            }
        ],
        "Queensland": [
            {
                month: "January",
                morning: "5:07 AM",
                evening: "6:45 PM"
            },
            {
                month: "February",
                morning: "5:30 AM",
                evening: "6:31 PM"
            },
            {
                month: "March",
                morning: "5:48 AM",
                evening: "6:03 PM"
            },
            {
                month: "April",
                morning: "6:04 AM",
                evening: "5:30 PM"
            },
            {
                month: "May",
                morning: "6:21 AM",
                evening: "5:07 PM"
            },
            {
                month: "June",
                morning: "6:35 AM",
                evening: "5:01 PM"
            },
            {
                month: "July",
                morning: "6:35 AM",
                evening: "5:10 PM"
            },
            {
                month: "August",
                morning: "6:16 AM",
                evening: "5:26 PM"
            },
            {
                month: "September",
                morning: "5:44 AM",
                evening: "5:40 PM"
            },
            {
                month: "October",
                morning: "5:10 AM",
                evening: "5:56 PM"
            },
            {
                month: "November",
                morning: "4:48 AM",
                evening: "6:16 PM"
            },
            {
                month: "December",
                morning: "4:47 AM",
                evening: "6:38 PM"
            }
        ],
        "Australian Capital Territory": [
            {
                month: "January",
                morning: "6:06 AM",
                evening: "8:18 PM"
            },
            {
                month: "February",
                morning: "6:35 AM",
                evening: "7:57 PM"
            },
            {
                month: "March",
                morning: "7:02 AM",
                evening: "7:20 PM"
            },
            {
                month: "April",
                morning: "6:27 AM",
                evening: "5:38 PM"
            },
            {
                month: "May",
                morning: "6:51 AM",
                evening: "5:07 PM"
            },
            {
                month: "June",
                morning: "7:09 AM",
                evening: "4:58 PM"
            },
            {
                month: "July",
                morning: "7:07 AM",
                evening: "5:10 PM"
            },
            {
                month: "August",
                morning: "6:41 AM",
                evening: "5:32 PM"
            },
            {
                month: "September",
                morning: "6:01 AM",
                evening: "5:54 PM"
            },
            {
                month: "October",
                morning: "6:07 AM",
                evening: "7:07 PM"
            },
            {
                month: "November",
                morning: "5:48 AM",
                evening: "7:47 PM"
            },
            {
                month: "December",
                morning: "5:43 AM",
                evening: "8:13 PM"
            }
        ],
        "Tasmania": [
            {
                month: "January",
                morning: "5:52 AM",
                evening: "8:46 PM"
            },
            {
                month: "February",
                morning: "6:30 AM",
                evening: "8:17 PM"
            },
            {
                month: "March",
                morning: "7:08 AM",
                evening: "7:29 PM"
            },
            {
                month: "April",
                morning: "6:43 AM",
                evening: "5:37 PM"
            },
            {
                month: "May",
                morning: "7:16 AM",
                evening: "4:57 PM"
            },
            {
                month: "June",
                morning: "7:38 AM",
                evening: "4:43 PM"
            },
            {
                month: "July",
                morning: "7:34 AM",
                evening: "4:57 PM"
            },
            {
                month: "August",
                morning: "7:00 AM",
                evening: "5:27 PM"
            },
            {
                month: "September",
                morning: "6:10 AM",
                evening: "5:59 PM"
            },
            {
                month: "October",
                morning: "6:06 AM",
                evening: "7:22 PM"
            },
            {
                month: "November",
                morning: "5:37 AM",
                evening: "8:13 PM"
            },
            {
                month: "December",
                morning: "5:27 AM",
                evening: "8:44 PM"
            }
        ],

    }

}