import React, { useContext, useState, useEffect } from 'react'
import AlertContext from '../../context/alert/alertContext';
import SpinnerContext from '../../context/spinner/spinnerContext';
import { Link } from 'react-router-dom';

import moment from 'moment';

import axios from 'axios';

import EventTable from '../../components/events/eventtable';

const Program = (props) => {
  const alertContext = useContext(AlertContext);
  const { setAlert, removeAlert } = alertContext;

  const spinnerContext = useContext(SpinnerContext);
  const { startSpinner, stopSpinner } = spinnerContext

  const [events, setEvents] = useState([]);

  const initView = {
    currview: "today",
    starttime: "",
    endtime: ""
  }
  const [view, setView] = useState(initView)
  const { currview, starttime, endtime } = view;

  const getMyEvents = async (payload) => {
    let url = process.env.REACT_APP_API_BASE_URL + "/events/getevents";
    const config = {
      headers: {
        "Content-Type": "application/json",
      }
    }



    try {
      startSpinner();
      let resp = await axios.post(url, payload, config);
      setEvents(resp.data);
    } catch (error) {
    }
    stopSpinner()
  }
  useEffect(() => {
    const payload = {
      timezone: moment.tz.guess(),
      period: "today"
    }
    getMyEvents(payload);
  }, [])


 


  const onShowView = (value) => {
    console.log(value)

  setEvents([]);
    setView({ ...view, currview: value });
    const payload = {
      timezone: moment.tz.guess(),
      period: value
    };

    (value != "advanced") &&
      getMyEvents(payload);

  }

  const onChange = (e) => {
    setView({ ...view, [e.target.name]: e.target.value })
  }


  const onAdvancedSearch=(e)=>{
    e.preventDefault();
    const payload = {
      timezone: moment.tz.guess(),
      period: "advanced",
      starttime,
        endtime
    };
    getMyEvents(payload);

  }


  return (
    <div className="uk-container-expand">
      <div className="uk-padding-small uk-margin-bottom uk-width-1-1">
        <span>
          <Link to="/addnewevent" className="uk-text-bolder uk-align-right">ADD NEW EVENT</Link>
        </span>

        <span className="uk-align-left">Time shown in <b>{moment.tz.guess()}</b> timezone</span>
      </div>

      <div className="uk-button-group uk-margin-top uk-align-left uk-width-1-1">
        <button className="uk-button uk-button-link uk-padding-small" onClick={() => onShowView("today")}>TODAY </button>
        <button className="uk-button uk-button-link uk-padding-small" onClick={() => onShowView("week")}>NEXT 7 DAYS </button>
        <button className="uk-button uk-button-link uk-padding-small" onClick={() => onShowView("advanced")}>ADVANCED</button>
      </div>

      <div>
        <h3 className="uk-text uk-text-center">{currview=="today" && "Showing Events for Today"}
            {currview=="week" && "Showing Events for next 7 days"}
            {currview=="advanced" && "Advanced Search..."}
        </h3>
      </div>
      {currview == "advanced" &&
        <div className="uk-margin-left">
          <form onSubmit={onAdvancedSearch}>

            <div className="uk-grid uk-child-width-1-3@s">
              <div className="">
                <span className="uk-text-meta uk-text">Set Start time:</span>
                <input className="uk-input" type="datetime-local" placeholder="Set Start Time" name="starttime" value={starttime} onChange={onChange} required />
              </div>

              <div className="">
                <span className="uk-text-meta">Set end time:</span>
                <input className="uk-input" type="datetime-local" placeholder="Set End Time" name="endtime" value={endtime} onChange={onChange} required />
              </div>
              <div>
                <span></span>
                <button className="uk-button uk-button-primary uk-margin-top" type="submit">SHOW</button>
              </div>

            </div>

          </form>
        </div>
      }
      <div className="uk-padding-top uk-width-1-1">
        <EventTable currview={currview} events={events} history={props.history} url="publicevents" />
      </div>


    </div>
  )
}

export default Program
