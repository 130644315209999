import React, { useState ,useContext} from 'react'

import { useHistory } from "react-router-dom";
import axios from 'axios';
import AlertContext from '../../context/alert/alertContext';
import SpinnerContext from '../../context/spinner/spinnerContext'

const Eventitem = ({ event }) => {
    const { _id, starttime, eventname, endtime,
        country, state, hostname, hostaddress, phone, meetingmode, meetingurl, email } = event
    let history = useHistory();
    const onUpdate = () => {
        history.push({
            pathname: "/addnewevent",
            state: { ...event }
        })
    }
    const initialState = {
        showDelete: false,
        memberref: ""
    }
    const alertContext = useContext(AlertContext);
    const spinnerContext = useContext(SpinnerContext);

    const { startSpinner, stopSpinner } = spinnerContext;
    const { setAlert } = alertContext;


    const [deleteForm, setDeleteForm] = useState(initialState);

    const { showDelete, memberref } = deleteForm;
    const showDeleteForm = (value) => {
        setDeleteForm({ ...deleteForm, showDelete: value })
    }

    const onChange=(e)=>{
        setDeleteForm({ ...deleteForm, [e.target.name]: e.target.value })
    }

    const onDelete=async(e)=>{
        startSpinner()
        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        }

        const payload={
            memberref,
            _id
        }

        try {
            let response = await axios.post(process.env.REACT_APP_API_BASE_URL + "/events/deleteevent", payload, config);
            history.push({
                pathname: "/program"
                
            })
            setAlert({ type: "success", msg: response.data.msg })
            //setState(intitialState);
        } catch (error) {
            setAlert({ type: "danger", msg: error.response.data.msg })

        }
        stopSpinner()
    }
    return (
        <>


            <div className="uk-card uk-card-body uk-text-center">


                <h3 className="uk-text-center uk-text-underline">Event Details</h3>


                <div className=" uk-card  uk-card-body uk-card-default  uk-align-center">
                    <div className="uk-grid  uk-child-width-1-2 uk-padding-top">
                        <div>  <h5>Event Description:</h5></div>
                        <div> {eventname}</div>
                    </div>


                    <div className="uk-grid  uk-child-width-1-2">
                        <div>  <h5>Meeting Mode:</h5></div>
                        <div> {meetingmode}</div>
                    </div>

                    {meetingmode == "ONLINE" ?

                        <div className="uk-grid  uk-child-width-1-2">
                            <div>  <h5>Meeting URL:</h5></div>
                            <div>  <a href={event.meetingurl} target="_blank">JOIN</a></div>
                        </div>
                        :
                        <div className="uk-grid  uk-child-width-1-2 ">
                            <div>  <h5>Address:</h5></div>
                            <div> {hostaddress}</div>
                        </div>}
                    <div className="uk-grid  uk-child-width-1-2 ">
                        <div>  <h5>Event Start Time:</h5></div>
                        <div> {starttime}</div>
                    </div>

                    <div className="uk-grid  uk-child-width-1-2 ">
                        <div>  <h5>Event End Time:</h5></div>
                        <div> {endtime}</div>
                    </div>
                    <div className="uk-grid  uk-child-width-1-2 ">
                        <div>  <h5>Hosted By:</h5></div>
                        <div> {hostname}</div>
                    </div>

                    <div className="uk-grid  uk-child-width-1-2 ">
                        <div>  <h5>State:</h5></div>
                        <div> {state}</div>
                    </div>
                    <div className="uk-grid  uk-child-width-1-2 ">
                        <div>  <h5>Country:</h5></div>
                        <div> {country}</div>
                    </div>
                    <div className="uk-grid  uk-child-width-1-2">
                        <div>  <h5>Phone:</h5></div>
                        <div> {phone}</div>
                    </div>
                    <div className="uk-grid  uk-child-width-1-2">
                        <div>  <h5>Email:</h5></div>
                        <div className="uk-text uk-text-break"> {email}</div>
                    </div>

                    <div>


                    </div>
                </div>

                {showDelete &&
                    <div className="uk-card-body uk-card  ">
                        <hr className="uk-divider-icon"></hr>
                        <div className=" uk-flex-center">
                            <div>
                                <span>Enter Member reference number:</span>
                                <span>(Check your email)</span>
                            </div>
                            <div>

                                <input className="uk-input uk-form-width-large"
                                 placeholder="Enter Member Ref Number" 
                                 name="memberref"
                                 value={memberref}
                                 onChange={onChange}
                                 /></div>
                            <div>
                                <button className="uk-button uk-button-danger" onClick={onDelete}>DELETE</button>
                                <button type="button" className="uk-button uk-button-primary" onClick={() => showDeleteForm(false)}>RESET</button>
                            </div>
                            <hr className="uk-divider-icon"></hr>

                        </div>



                    </div>
                }
                {!showDelete &&
                    <div className="uk-button-group uk-align-center">
                        <button className="uk-button uk-button-primary " onClick={() => history.goBack()}>BACK</button>
                        <button className="uk-button-secondary uk-button" onClick={onUpdate}>Update</button>
                        <button className="uk-button-danger uk-button" onClick={() => showDeleteForm(true)}>DELETE</button>
                    </div>
                }
            </div>
        </>
    )
}

export default Eventitem
